<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div>
          <b-card-group deck>
            <b-card header-tag="header" footer-tag="footer">
              <template v-slot:header>
                <h6 class="mb-0">Danh sách lịch sử hoàn thành nhiệm vụ</h6>
              </template>
              <div class="filter-transaction">
                <div class="row mb-6">
                  <div class="col-lg-3 mb-lg-0 mb-6">
                    <label>Số điện thoại:</label>
                    <input
                      type="text"
                      class="form-control datatable-input"
                      v-model="paramFilter.phone"
                    />
                  </div>

                  <!--                  <div class="col-lg-2 mb-lg-0 mb-6">-->
                  <!--                    <label>Trạng thái:</label>-->
                  <!--                    <select-->
                  <!--                        class="form-control datatable-input"-->
                  <!--                        data-col-index="7"-->
                  <!--                        v-model="paramFilter.status"-->
                  <!--                    >-->
                  <!--                      <option value="">Tắt cả</option>-->
                  <!--                      <option value="success">PENDING</option>-->
                  <!--                      <option value="fail">SUCCESS</option>-->
                  <!--                      <option value="pending">FAIL</option>-->
                  <!--                    </select>-->
                  <!--                  </div>-->

                  <div class="col-lg-4 mb-lg-0 mb-6">
                    <label>Thời gian:</label>
                    <div class="input-daterange input-group" id="kt_datepicker">
                      <input
                        type="date"
                        v-model="paramFilter.date_from"
                        class="form-control datatable-input"
                        name="start"
                        placeholder="Từ"
                      />
                      <div class="input-group-append">
                        <span class="input-group-text">
                          <i class="la la-ellipsis-h"></i>
                        </span>
                      </div>
                      <input
                        type="date"
                        v-model="paramFilter.date_to"
                        class="form-control datatable-input"
                        name="end"
                        placeholder="Đến"
                      />
                    </div>
                  </div>
                  <div class="col-lg-2 mb-lg-0 mb-6">
                    <label>.</label><br />
                    <button
                      class="btn btn-primary btn-primary--icon"
                      @click="loadData()"
                    >
                      <span>
                        <i class="la la-search"></i>
                        <span>Tìm kiếm</span>
                      </span>
                    </button>
                  </div>
                </div>
              </div>

              <b-table
                table-class="datatable-table"
                hover
                tbody-tr-class="datatable-row"
                thead-tr-class="datatable-row"
                thead-class="datatable-head"
                details-td-class="datatable-cell"
                :items="items"
                :fields="fields"
              >
                <template #cell(user_info)="data">
                  <span> SĐT : {{ data.item.phone }} </span> <br />
                  <span> User ID: {{ data.item.uuid }} </span><br />
                </template>
                <template #cell(boxes)="data">
                  <span> {{ data.item.boxes }} hộp quà </span> <br />
                  <span> Giá trị: {{ data.item.amount }}đ </span><br />
                </template>
                <template #cell(status_pending)="data">
                  <span
                    class="
                      label
                      font-weight-bold
                      label-lg label-inline label-light-success
                    "
                    v-if="data.item.status != 0"
                  >
                    Success
                  </span>
                  <span
                    class="
                      label
                      font-weight-bold
                      label-lg label-inline label-light-warning
                    "
                    v-else
                  >
                    Pending
                  </span>
                </template>
                <template #cell(status_success)="data">
                  <span
                    class="
                      label
                      font-weight-bold
                      label-lg label-inline label-light-success
                    "
                    v-if="data.item.status == 2"
                  >
                    Success
                  </span>
                  <span
                    class="
                      label
                      font-weight-bold
                      label-lg label-inline label-light-warning
                    "
                    v-else
                  >
                    Pending
                  </span>
                </template>
              </b-table>
              <div class="mt-3">
                <b-pagination-nav
                  v-model="currentPage"
                  :link-gen="createLinkPage"
                  :number-of-pages="paginate.totalPage"
                  use-router
                  first-number
                  last-number
                  align="right"
                ></b-pagination-nav>
              </div>
            </b-card>
          </b-card-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Common from "@/core/mixins/common";

const WalletRunRepository = RepositoryFactory.get("WalletRun");

export default {
  mixins: [Common],
  data() {
    return {
      paramFilter: {
        phone: "",
        status: "",
        date_from: new Date().toJSON().slice(0, 10).replace(/-/g, "-"),
        date_to: new Date().toJSON().slice(0, 10).replace(/-/g, "-"),
        limit: 20,
      },
      fields: [
        // {
        //   key: "id",
        //   label: "ID",
        // },
        {
          key: "user_info",
          label: "Thông tin users",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "title",
          label: "Thử thách",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "name",
          label: "Cấp độ",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "boxes",
          label: "Phần thưởng",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "created_at",
          label: "Thời gian",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "status_pending",
          label: "Trạng thái hoàn thành nhiệm vụ",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "status_success",
          label: "Trạng thái nhận thưởng",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
      ],
      items: [],
      currentPage: 1,
      paginate: {
        limit: 20,
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0,
      },
    };
  },
  created() {
    this.loadData();
  },
  watch: {
    currentPage() {
      this.loadData();
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Thống kê" },
      {
        title: "Lịch sử hoàn thành nhiệm vụ",
        route: "/wallet-run/report/missions",
      },
    ]);
  },
  methods: {
    async loadData() {
      try {
        this.$bus.$emit("show-loading", true);
        this.paramFilter.page = this.currentPage;
        let response = await WalletRunRepository.getMissionHistories(
          this.paramFilter
        );
        this.$bus.$emit("show-loading", false);
        let body = response.data.data;

        this.items = body.data;
        this.currentPage = body.current_page;
        this.paginate.total = body.total;
        this.paginate.totalPage = body.last_page;
      } catch (error) {
        this.$bus.$emit("show-loading", false);
      }
    },
  },
};
</script>

<style scoped></style>
